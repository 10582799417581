import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import SignupRightSide from "./signup/SignupRightSide";
import Logo from "./Logo";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import CheckIcon from "@mui/icons-material/Check";
import { inviteCodePageSchema } from "../service/validationschemas/validation-schemas";
import AuthLayout from "./Auth/AuthLayout";
import AuthWrapper from "./Auth/AuthWrapper";

function InviteCode() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const providedCode = searchParams.get("inviteCode");
  const sanitizedCode = (providedCode ?? "").replace(/[^a-zA-Z0-9-_]/g, '');

  const formik = useFormik({
    initialValues: {
      invitationCode: "",
    },
    validationSchema: inviteCodePageSchema,

    onSubmit: (values) => {
      setLoading(true)
      navigate("/auth/sign-up", {
        state: {
          invitationCode: values.invitationCode,
        },
      });
    },
  });

  useEffect(() => {
    if (location?.state?.code) {   
      formik.setFieldValue('invitationCode', location?.state?.code);
    } else if (sanitizedCode) {
      formik.setFieldValue('invitationCode', sanitizedCode);
    }
  }, []);

  const getFormErrorMessage = (msg) => {
    return (
      <small className={`${formik.errors[msg] ? "block" : "hidden"} p-error`} style={{color:'#FF3939'}}>
        {formik.errors[msg]}
      </small>
    );
  };

  return (
    <AuthLayout>
      <AuthWrapper className="bg-black-alpha-90 text-white">
        <Link to="https://www.mymirror360.com/" target="_blank">
          <Logo variant="white" className={"flex align-center"} />
        </Link>
        <form
          onSubmit={formik.handleSubmit}
          className="max-w-sm p-input-filled flex flex-column gap-5"
        >
          <h1 className="text-center text-2xl text-balance">
            Enter your
            <br className="block sm:hidden" /> invitation code
          </h1>
          <div className=" flex flex-column gap-3">
            <label className="text-sm opacity-50" htmlFor="invitation code">
              Invitation Code*
            </label>
            <div className="flex p-input-icon-right">
              <InputText
                className="inviteInput surface-800 text-white"
                placeholder="AAA-AAA-AAAA"
                value={formik.values.invitationCode}
                onChange={(e) => {
                  formik.setFieldValue("invitationCode", e.target.value);
                }}
              />
              {formik.isValid && formik.dirty && (
                <CheckIcon fontSize="inherit" style={{ color: "green" }} />
              )}
            </div>
            {getFormErrorMessage("invitationCode")}
            <Button
              type="submit"
              label="Proceed"
              className="min-w-max w-full"
              loading={loading}
            />
            <p className="text-sm opacity-80">
              My Mirror 360 is currently only available to invited parties. If
              you do not have an invitation, you can join the waitlist. Based on
              your position in the queue, we will send you an invitation as soon
              as it’s your turn.
            </p>
          </div>
        </form>
        <div className="w-full" />
      </AuthWrapper>
      <SignupRightSide />
    </AuthLayout>
  );
}

export default InviteCode;
